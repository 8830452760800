// !DO NOT MODIFY THESE CLASSES UNLESS IT IS A GLOBAL CHANGE.

// * MODIFY THE PROPERTIES WITHIN YOUR COMPONENT
// <MUIComponent sx={{...commonTextStyles, fontFamily: 'Open Sans' }} />

// * ADD THE EXTRA PROPERTIES WITHIN YOUR COMPONENT
// <MUIComponent sx={{...commonTextStyles, lineHeight: '2rem }} />

// For MUI components when disabled
const primaryFontFamily = {
  // fontFamily: "DM Sans",
};

const readOnlyBackground = {
  backgroundColor: "#e9ecef",
  color: "#05499c",
};

// Common Font styles
const commonTextStyles = {
  fontStyle: "normal",
  textTransform: "capitalize",
  color: "#000000",
};

// Common Error Message text
const errorMsgStyle = {
  fontStyle: "normal",
  textTransform: "normal",
  color: "#eb4034",
  fontSize: "0.7rem",
  marginTop: "0.3rem",
};

// Blue Button
const blueBtnStyle = {
  fontStyle: "normal",
  textTransform: "capitalize",
  background: "#032b5b",
  color: " #FFF",
  ":hover": {
    background: "#043d81",
    color: " #FFF",
  },
  ":disabled": {
    background: "#666",
    color: " #fff",
  },
};

const redBtnStyle = {
  fontStyle: "normal",
  textTransform: "capitalize",
  background: "#eb4034",
  color: " #FFF",
  ":hover": {
    background: "#eb4034",
    color: " #FFF",
  },
  ":disabled": {
    background: "#666",
    color: " #fff",
  },
};

// style for * which indicates a field is important
const mandatoryStyle = {
  color: "#eb4034",
  fontSize: "inherit",
};

export {
  readOnlyBackground,
  commonTextStyles,
  blueBtnStyle,
  errorMsgStyle,
  mandatoryStyle,
  primaryFontFamily,
};
