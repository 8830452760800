import axios from 'axios'
import { UserData } from '../store/user-data'

export default class NodeAPI {
  static tokenType = 'user'

  static getHeaders(isApiKey) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: isApiKey ? import.meta.env.VITE_APP_NODE_API_KEY : UserData.token
    }
    return headers
  }

  static init() {
    // Add a request interceptor
    axios.interceptors.request.use(
      config => {
        const originalRequest = config
        const apiToken = import.meta.env.VITE_APP_NODECLIENTKEY
        // const userToken = UserData.token; // Replace this with your actual code to get the user's token

        if (originalRequest && originalRequest.headers) {
          originalRequest.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate'
          originalRequest.headers['Pragma'] = 'no-cache'
          originalRequest.headers['Expires'] = '0'
          if (NodeAPI.tokenType === 'api' && apiToken) {
            originalRequest.headers.Authorization = `APIKEY-${apiToken}`
          }
          //   else if (NodeAPI.tokenType === "user" && userToken) {
          //     originalRequest.headers.Authorization = `${userToken}`;
          //   }
        }

        return config // Modify and return other requests
      },
      error => Promise.reject(error)
    )
    // Add a response interceptor
    axios.interceptors.response.use(
      response => response,
      error => {
        console.log('error response', error)
        if (error.response?.status === 401) {
          console.log('redirect to login')
          // window.location.href = `${process.env.VUE_APP_SL_URL}/Login.aspx`;
        }
        return Promise.reject(error)
      }
    )
  }

  static async sendOTP(mobileNumber, success, failure) {
    // Set the headers to specify JSON content, false means it is a token
    const headers = {
      'Content-Type': 'application/json',
      Authorization: import.meta.env.VITE_APP_NODE_API_KEY
    }
    return axios
      .post(
        import.meta.env.VITE_APP_NODEAPI + `api/authentication/otp?mobileNo=${mobileNumber}`,
        {}, // data object, if you have any data to send in the request body, it goes here
        {
          headers
        }
      )
      .then(response => {
        if (success) {
          success(response.data)
        }
      })
      .catch(error => {
        if (failure) {
          failure(error)
        }
      })
  }

  static async EmailUserCreation(data, success, failure) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: import.meta.env.VITE_APP_NODE_API_KEY
    }
    return axios
      .post(import.meta.env.VITE_APP_NODEAPI + `api/authentication/signUp`, data, {
        headers
      })
      .then(response => {
        if (success) {
          success(response.data)
        }
      })
      .catch(error => {
        if (failure) {
          failure(error)
        }
      })
  }

  static async verifyUserEmail(data, success, failure) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: import.meta.env.VITE_APP_NODE_API_KEY
    }
    return axios
      .post(import.meta.env.VITE_APP_NODEAPI + `api/authentication/login/email`, data, {
        headers
      })
      .then(response => {
        if (success) {
          success(response.data)
        }
      })
      .catch(error => {
        if (failure) {
          failure(error)
        }
      })
  }
}
