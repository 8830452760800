import React, { useState, useEffect, useContext, useMemo } from 'react'
import { validateName, validatePhoneNumber, validatePassword } from '../../utils/validation'
import { CustomErrorText, CustomMandatoryText } from '../../components/UI/MUIComponents'
import CircularProgress from '@mui/material/CircularProgress'
import { Checkbox, Tooltip } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import NodeAPI from '../../api/NodeAPI'
import { UserData } from '../../store/user-data'
import { Col, Modal, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AppStateContext } from '../../state/AppProvider'
import QuotesData from './QuotesData'
import ivest4Edu from '../../components/Login/assets/i4E_icon.svg'

function SignupWithEmail(props) {
  const [hasClientEmail, setHasClientEmail] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const clientEmail = sessionStorage.getItem('CLientEmail')
    if (clientEmail) {
      setHasClientEmail(true)
      setInputData(prevData => ({
        ...prevData,
        emailID: clientEmail
      }))
    }
  }, [])

  const initialInputData = {
    firstName: '',
    lastName: '',
    mobileNo: '',
    emailID: '',
    password: '',
    referralCode: (typeof sessionStorage !== 'undefined' && sessionStorage.getItem('Referal')) || ''
  }

  const initialErrors = {
    firstName: '',
    lastName: '',
    mobileNo: '',
    emailID: '',
    password: ''
  }

  const [inputData, setInputData] = useState(initialInputData)
  const [inputErrors, setInputErrors] = useState(initialErrors)
  const [globalError, setGlobalError] = useState('')
  const [mobileNoError, setMobileNoError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [count, setCount] = useState(0)
  const [emailCount, setEmailCount] = useState(0)
  const [redirectCount, setRedirectCount] = useState(0)

  // dispatch to set JWT token in global state to be used in other components
  const { dispatch } = useContext(AppStateContext)

  const randomIndex = useMemo(() => {
    return Math.floor(Math.random() * QuotesData.length)
  }, [])
  const content = QuotesData[randomIndex]

  const handleClose = () => {
    props.setShowSignUpModal(false)
  }

  const handleChange = e => {
    if (e.target.name === 'firstName' || e.target.name === 'lastName') {
      if (e.target.value && e.target.value.length > 250) {
        return
      }
      clearErrorOnInput(e.target.name)
    }
    if (e.target.name === 'mobileNo') {
      if (e.target.value.length > 10) {
        return
      }
      const value = e.target.value.replace(/\D/, '').replace(/,/g, '') ?? ''
      setInputData({
        ...inputData,
        [e.target.name]: value
      })
      typeof sessionStorage !== 'undefined' && sessionStorage.setItem('clientID', value)
      clearErrorOnInput(e.target.name)
      return
    }
    setInputData({ ...inputData, [e.target.name]: e.target.value })
  }

  const clearErrorOnInput = name => {
    // clear the error message if the user is correcting the fields
    if (inputErrors[name] && inputErrors[name] !== '') {
      setInputErrors(oldState => {
        const newState = { ...oldState, [name]: '' }
        return newState
      })
    }
  }

  const validation = () => {
    let error = {}
    if (!props.existingUser) {
      if (inputData.firstName === '') {
        error.firstName = 'First Name is required'
      } else {
        error.firstName = validateName(inputData.firstName)
      }
      if (inputData.lastName === '') {
        error.lastName = 'Last Name is required'
      } else {
        error.lastName = validateName(inputData.lastName)
      }
      if (!hasClientEmail && inputData.emailID === '') {
        error.emailID = 'Email is required'
      } else {
        error.emailID = ''
      }
      if (inputData.password === '') {
        error.password = 'Password is required'
      } else if (!validatePassword(inputData.password)) {
        error.password =
          'Password should be at least 8 characters long and should include at least one uppercase letter, one lowercase letter, one number and one special character'
      } else {
        error.password = ''
      }
    }
    if (inputData.mobileNo !== '') {
      error.mobileNo = validatePhoneNumber(inputData.mobileNo)
    }
    const error_values = Object.values(error)
    if (error_values.every(item => item === '')) {
      setInputErrors(initialErrors)
      setGlobalError('')
      return true
    } else {
      setInputErrors(error)
      return false
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (validation()) {
      setGlobalError('')
      setEmailError('')
      setMobileNoError('')
      let data = {
        emailId: inputData.emailID,
        firstName: inputData.firstName,
        lastName: inputData.lastName,
        mobileNo: inputData.mobileNo ? inputData.mobileNo : null,
        password: inputData.password,
        referralCode: inputData.referralCode.toUpperCase(),
        dateOfBirth: null,
        genderId: 0,
        consentForCommunication: 0
      }
      clientEmailSignUpUser(data)
      console.log(data)
    } else {
      setGlobalError('Please fill the fields correctly and submit again')
    }
  }
  async function clientEmailSignUpUser(data) {
    setIsLoading(true)
    try {
      NodeAPI.EmailUserCreation(
        data,
        async response => {
          if (response.responseCode === 200) {
            if (response.data.EmailIdExists) {
              // toastMessage(
              //     "This email address is already in use. Please try a different email."
              // );
              setEmailError('This email address is already in use. Please try a different email.')
              // setGlobalError('This email address is already in use. Please try a different email.')
              setIsLoading(false)
              return
            } else if (response.data.MobileNoExists) {
              // toastMessage(
              //     "This mobile number is already in use. Please try a different one."
              // );
              setMobileNoError('This mobile number is already in use. Please try a different one.')
              // setGlobalError('This mobile number is already in use. Please try a different one.')
              setIsLoading(false)
              return
            } else if (response.data.error !== null) {
              // toastMessage(response.data.error);
              setGlobalError(response.data.error)
              setIsLoading(false)
              return
            } else {
              setGlobalError('')
              setEmailError('')
              setMobileNoError('')
              let obj = {
                emailId: data.emailId,
                password: data.password
              }
              NodeAPI.verifyUserEmail(
                obj,
                async response => {
                  if (response.responseCode === 200) {
                    if (response.data.isProfileAvailable) {
                      setCount(0)
                      setRedirectCount(0)
                      sessionStorage.setItem('RedirectCount', redirectCount)
                      sessionStorage.setItem('count', count)
                      setEmailCount(0)
                      sessionStorage.setItem('EmailCount', emailCount)
                      sessionStorage.setItem('JWT', response.data.jwtToken)
                      // dispatch the action to set the JWT token
                      dispatch({ type: 'SET_JWT_TOKEN', payload: response.data.jwtToken })
                      UserData.token = response.data.jwtToken
                      if (!props.skipRedirect) {
                        if (props.redirectTo === undefined) {
                          // window.location.href = '/#/chat'
                          window.location.href = '/#/'
                        } else {
                          if (props.redirectUser === undefined) {
                            let currentURL = window.location.href
                            window.location.href = currentURL
                          } else {
                            window.location.href = import.meta.env.VITE_APP_WEBSITE_URL + props.redirectUser
                          }
                        }
                      }
                      typeof sessionStorage !== 'undefined' && sessionStorage.setItem('CLientEmail', e.target.value)
                      setIsLoading(false)
                    } else {
                      setIsLoading(false)
                      toast.error('Oops! Something went wrong. Please try again!', {})
                    }
                  } else {
                    toast.error('Oops! Something went wrong. Please try again!', {})
                    setIsLoading(false)
                  }
                },
                () => {
                  toast.error('Oops! Something went wrong. Please try again!', {})
                }
              )
            }
          } else {
            setIsLoading(false)
            toast.error('Oops! Something went wrong. Please try again!', {})
            return
          }
        },
        () => {
          toast.error('Oops! Something went wrong. Please try again!', {})
        }
      )
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  return (
    <Modal
      show={props.showSignUpModal}
      onHide={handleClose}
      className="modal-container emailVerify"
      dialogClassName="modal-90w"
      backdrop="static"
      keyboard={false}
      style={{ zIndex: '1400' }}>
      <Modal.Body>
        <Row className="d-flex">
          {/* {modalType === '' && ( */}
          <Col className="col-sm-6 modal-left-content">
            <p className="quote fs-30" style={{ fontStyle: 'italic' }}>
              {' '}
              &ldquo;{content.quote}&rdquo;.
            </p>
            <p className="author" style={{ fontStyle: 'italic' }}>
              {content.author}{' '}
            </p>
          </Col>
          <Col className="col-sm-12 col-md-12 col-lg-6 p-0 d-flex justify-content-center align-items-center">
            <div className="modal-right-content">
              <div className="modal-rightWrapper">
                <h4>
                  <img src={ivest4Edu} alt="ivest4Edu icon" className="modal-invest4Edu-icon" />
                </h4>
                <div className="modal-createAccount">
                  <h6>Create your Account</h6>
                  <p>Almost there... we are preparing something great for you!</p>
                  <form action="" className="form-flex" onSubmit={handleSubmit}>
                    <div className="row ps-sm-0">
                      <>
                        <label htmlFor="firstName" className="p-0">
                          First Name*
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          id="firstName"
                          placeholder="Eg: Tommy"
                          value={inputData.firstName}
                          onChange={handleChange}
                          className="custom_modal_inputField mb-2"
                          required
                        />
                        {inputErrors.firstName && <CustomErrorText>{inputErrors.firstName}</CustomErrorText>}
                        <label htmlFor="lastName" className="p-0">
                          Last Name*
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          id="lastName"
                          placeholder="Eg: Williamson"
                          value={inputData.lastName}
                          onChange={handleChange}
                          className="custom_modal_inputField mb-2"
                          required
                        />
                        {inputErrors.lastName && <CustomErrorText>{inputErrors.lastName}</CustomErrorText>}
                      </>
                      <>
                        <label htmlFor="mobileNo" className="p-0">
                          Mobile No
                        </label>
                        <input
                          type="text"
                          name="mobileNo"
                          id="mobileNo"
                          placeholder="Eg: 9876543210"
                          value={inputData.mobileNo}
                          onChange={handleChange}
                          className="custom_modal_inputField mb-2"
                        />
                        {inputErrors.mobileNo && (
                          <CustomErrorText sx={{ padding: '0px', fontSize: '0.8rem' }}>
                            {inputErrors.mobileNo}
                          </CustomErrorText>
                        )}
                        {mobileNoError && (
                          <CustomErrorText sx={{ padding: '0px', fontSize: '0.8rem' }}>{mobileNoError}</CustomErrorText>
                        )}
                      </>
                      <label htmlFor="emailID" className="p-0">
                        Email ID*
                      </label>
                      <input
                        type="email"
                        name="emailID"
                        id="emailID"
                        placeholder="Eg: your@email.com"
                        value={hasClientEmail ? inputData.emailID : inputData.emailID}
                        onChange={e => {
                          handleChange(e)
                        }}
                        className="custom_modal_inputField mb-2"
                        required
                        disabled={hasClientEmail ? true : false}
                      />
                      {inputErrors.emailID && (
                        <CustomErrorText sx={{ padding: '0px', fontSize: '0.8rem' }}>
                          {inputErrors.emailID}
                        </CustomErrorText>
                      )}
                      {emailError && (
                        <CustomErrorText sx={{ padding: '0px', fontSize: '0.8rem' }}>{emailError}</CustomErrorText>
                      )}

                      <div style={{ position: 'relative' }} className="p-0">
                        <label htmlFor="password" className="mt-0 custom_modal_label">
                          Password <CustomMandatoryText>*</CustomMandatoryText>
                        </label>
                        <input
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          id="password"
                          placeholder="Minimum 8 characters"
                          value={inputData.password}
                          onChange={handleChange}
                          className="custom_modal_inputField mb-2"
                          required
                        />
                        <Tooltip title={showPassword ? 'Hide Password' : 'Show Password'}>
                          <Checkbox
                            checked={showPassword}
                            icon={<VisibilityIcon />}
                            checkedIcon={<VisibilityOffIcon />}
                            onChange={() => setShowPassword(!showPassword)}
                            size="small"
                            color="default"
                            sx={{ position: 'absolute', right: '2%', bottom: '15%' }}
                          />
                        </Tooltip>
                      </div>
                      {inputErrors.password && <CustomErrorText>{inputErrors.password}</CustomErrorText>}
                      <label htmlFor="referralCode" className="p-0">
                        Referral Code
                      </label>
                      <input
                        type="text"
                        name="referralCode"
                        id="referralCode"
                        placeholder="Please add the referral code."
                        value={inputData.referralCode}
                        onChange={handleChange}
                        className="custom_modal_inputField mb-2"
                      />

                      {globalError && <CustomErrorText>{globalError}</CustomErrorText>}
                      <button disabled={isLoading} type={'submit'} className="btn btn-primary requestOTP-btn mt-3">
                        {' '}
                        {isLoading && <CircularProgress size={20} />}
                        Continue
                      </button>
                      <p className="custom_modal_para mb-0 mt-2">
                        Already have an account ?{' '}
                        <span style={{ color: '#007bff', cursor: 'pointer' }} onClick={() => props.setLoginModal(true)}>
                          {' '}
                          Login here.
                        </span>
                      </p>
                    </div>
                  </form>
                </div>
                <p className="custom_modal_para">
                  By continuing, I agree to{' '}
                  <Link className="privacyPolicy" href="/PrivacyPolicy" target="_blank">
                    Privacy Policy
                  </Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default SignupWithEmail
