import React, { useContext, useState } from 'react'
import i4eLogo from '../../assets/Final Invest4Edu Logo_Reverse 1.svg'
import './ChatbotUI.css'
import { AppStateContext } from '../../state/AppProvider'

const ChatbotUI = ({
  showChatHistoryPanel,
  setShowChatHistoryPanel,
  categories,
  filteredQuestions,
  onFilterQuestions,
  onQuestionChange,
  onSetCategories,
  onSetFilteredQuestions
}: any) => {
  const appStateContext = useContext(AppStateContext)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedQuestion, setSelectedQuestion] = useState(null)
  const [visibleQuestions, setVisibleQuestions] = useState(5) // State to track visible questions

  const handleCategoryClick = (category: any) => {
    setSelectedCategory(category)
    onFilterQuestions(category)
    setVisibleQuestions(5) // Reset visible questions when category changes
  }

  const handleQuestionClick = (question: any) => {
    setSelectedQuestion(question)
    onQuestionChange(question.QuestionText, appStateContext?.state.currentChat?.id)
  }

  // Load more questions when user scrolls to the bottom
  const loadMoreQuestions = () => {
    setVisibleQuestions(prevVisibleQuestions => prevVisibleQuestions + 5)
  }

  // show less questions based on the previous state of visible questions state
  const showLessQuestions = () => {
    setVisibleQuestions(prevVisibleQuestions => prevVisibleQuestions - 5)
    // setVisibleQuestions(5)
  }

  return (
    <div className="chat-container" style={{ padding: '0rem' }}>
      <div
        style={{ backgroundColor: '#203A73', width: '100%', padding: '1rem 2rem', borderRadius: '20px 20px 0px 0px' }}>
        <img src={i4eLogo} alt="i4e-logo-header" />
      </div>
      <div className="inner-chatcontainer" style={{ padding: '1rem' }}>
        {/* CATEGORIES SECTION */}
        <div className="categories-container">
          {categories &&
            categories.map((category: any) => {
              return (
                <div
                  key={category}
                  className={`cardCategory ${selectedCategory === category ? 'active' : ''}`}
                  onClick={() => handleCategoryClick(category)}>
                  <h4>{category}</h4>
                </div>
              )
            })}
        </div>
        {/* Render filtered questions based on category */}
        <div className="questions-container">
          {filteredQuestions &&
            filteredQuestions.slice(0, visibleQuestions).map((question: any) => {
              return (
                <div
                  key={question.QuestionText}
                  className={`cardQuestion ${selectedQuestion === question ? 'active' : ''}`}
                  onClick={() => handleQuestionClick(question)}>
                  <h5>{question.QuestionText}</h5>
                </div>
              )
            })}
        </div>
        {/* Show Load More button if there are more questions to load */}
        <div className="load-more-container">
          {visibleQuestions < filteredQuestions.length && (
            <button className="load-more-button" onClick={loadMoreQuestions}>
              Show More &#9660;
            </button>
          )}
          {visibleQuestions > 5 && (
            <button className="load-more-button" onClick={showLessQuestions}>
              Show Less &#9650;
            </button>
          )}
        </div>
        {/* Show Load Less button if there are more questions to load */}
        {/* {visibleQuestions > 5 && (
          <div className="load-more-container">
            <button className="load-more-button" onClick={showLessQuestions}>
              Show Less &#9650;
            </button>
          </div>
        )} */}
      </div>
    </div>
  )
}

export default ChatbotUI
