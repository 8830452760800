const UserData = {
  token: '',
  UserID: '',
  device: '',
  RedirectFrom: '',
  PorductName: '',
  IsInvestkulTest: false
}
UserData.token = sessionStorage.getItem('JWT')
export { UserData }
