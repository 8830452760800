import React from 'react'
import ReactDOM from 'react-dom/client'
import { HashRouter, Route, Routes, BrowserRouter, useLocation, Navigate } from 'react-router-dom'
import { initializeIcons } from '@fluentui/react'

import Chat from './pages/chat/Chat'
import Layout from './pages/layout/Layout'
import NoPage from './pages/NoPage'
import { AppStateProvider } from './state/AppProvider'

import './index.css'
import Login from './pages/auth/Login'

initializeIcons()

const ProtectedRoute = ({ children }: any) => {
  const location = useLocation()
  const token = sessionStorage.getItem('JWT') // Assuming 'jwtToken' is your token's key
  // Step 2 & 3: Check for token and render children or redirect
  if (!token) {
    // Redirect to login page, preserving the intended location
    return <Navigate to="/" state={{ from: location }} replace />
  }
  return children
}

export default function App() {
  return (
    <AppStateProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index path="/" element={<Chat />} />
            <Route path="login" element={<Login />} />
            {/* <Route
              path="chat"
              element={
                <ProtectedRoute>
                  <Chat />
                </ProtectedRoute>
              }
            /> */}
            <Route path="*" element={<NoPage />} />
          </Route>
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </AppStateProvider>
  )
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
