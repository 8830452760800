import React, { useState, useEffect, memo } from "react";

function CustomTimer(props) {
  const [seconds, setSeconds] = useState(180);
  // const [timerStarted, setTimerStarted] = useState(false);

  useEffect(() => {
    let interval;
    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      props.timerExpired();
    }
    // Cleanup function to clear the interval when the component unmounts or when timer stops
    return () => clearInterval(interval);
  }, [seconds]);

  // Convert remaining seconds to mm:ss format
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return (
    <>
      Time Remaining: {minutes < 10 ? "0" : ""}
      {minutes}:{remainingSeconds < 10 ? "0" : ""}
      {remainingSeconds}
    </>
  );
}

export default memo(CustomTimer);
