import { CommaFormater, CommaFormater1 } from "./converter";
import { get1 } from "./service-facade";
import { isBrowser } from "./storageUtils";

export function addDaysToDate(date: any, days: any) {
  var res = new Date(date);
  res.setDate(res.getDate() + days);
  return res;
}
export function padTo2Digits(num: any) {
  return num.toString().padStart(2, "0");
}

export function formatDate(date: any) {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join("/");
}

export async function GetUserProfile() {
  if (typeof sessionStorage !== "undefined" && sessionStorage.getItem("JWT")) {
    const userResponse = await get1("i4e/v1/userprofile");
    // console.log("userResponse",userResponse)
    return userResponse;
  }
}

export function ConvertDate(date_string: String) {
  if (date_string != null && date_string != undefined && date_string != "") {
    var date_components = date_string?.split("T");
    var newDate = date_components[0];
    var newDate1 = newDate.split("-");
    return newDate1[2] + "/" + newDate1[1] + "/" + newDate1[0];
  }
}

export function convertDateTo(str: any) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

export function addSuffix(i: any) {
  var a = i % 10,
    b = i % 100;

  if (a == 1 && b != 11) {
    return i + "st";
  } else if (a == 2 && b != 12) {
    return i + "nd";
  } else if (a == 3 && b != 13) {
    return i + "rd";
  } else {
    return i + "th";
  }
}

export function toastMessage(str: any) {
  const event = new CustomEvent("errorMassage", { detail: str });
  window.dispatchEvent(event);
}
export function toastMessageSuccess(str: any) {
  const event = new CustomEvent("SuccesMessage", { detail: str });
  window.dispatchEvent(event);
}

export function toTitleCase(str: any) {
  return str.replace(/\w\S*/g, function (txt: any) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function ConvertIntoCR(val: any) {
  // let val = Math.abs(value)
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2);
    const val1 = CommaFormater1(val);
    const value = val1 + " Cr";
    return value;
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2);
    const val1 = CommaFormater1(val);
    const value = val1 + " Lac";
    return value;
  }

  //
}

export function DateFormaterddMMYYYY(val: any) {
  const [year, month, day] = val?.split("-");

  const result = [day, month, year].join("-");
  return result;
}
export function DateFormaterDMY(val: any) {
  const [year, month, day] = val[0]?.split("-");

  const result = [day, month, year].join("-");
  return result;
}

// export async function GetSchemeList(PageNumber,NofoScheme,SchemeName,AmcCode:[],ATFundLevelCategoryName,MRRatingOverall,dividendReinvestmentFlag,endDate,schemeType,sipmininvest,Return){
//  console.log("AMCODE",AmcCode);
//     const SchemeList = await get1("i4e/v1/morningStar/getMFSchemes?AMCCode="+AmcCode+"&ATFundLevelCategoryName="+ATFundLevelCategoryName+"&MRRatingOverall="+MRRatingOverall+"&dividendReinvestmentFlag="+dividendReinvestmentFlag+"&endDate="+endDate+"&pageNo="+PageNumber+"&pageSize="+NofoScheme +"&returns="+Return+"&schemeName="+SchemeName+"&schemeType="+schemeType+"&sipmininvest="+sipmininvest);
//     return SchemeList

// }
export async function GetSchemeList(
  PageNumber: any,
  NofoScheme: any,
  SchemeName: any,
  AmcCode: any,
  ATFundLevelCategoryName: any,
  MRRatingOverall: any,
  dividendReinvestmentFlag: any,
  endDate: any,
  schemeType: any,
  sipmininvest: any,
  Return: any,
  sortMethod: any
) {
  console.log("AMCODE", AmcCode);

  const queryParams = [
    `amcCode=${AmcCode}`,
    `atFundLevelCategoryName=${ATFundLevelCategoryName}`,
    `mrRatingOverall=${MRRatingOverall}`,
    `dividendReinvestmentFlag=${dividendReinvestmentFlag}`,
    `endDate=${endDate}`,
    `pageNo=${PageNumber}`,
    `pageSize=${NofoScheme}`,
    `dpReturn=${Return}`,
    `schemeName=${SchemeName}`,
    `schemeType=${schemeType}`,
    `sipMinInvest=${sipmininvest}`,
    `sortingType=${sortMethod}`,
  ];

  const queryString = queryParams.join("&");

  const SchemeList = await get1(
    `i4e/v1/morningStar/getMFSchemes?${queryString}`
  );
  return SchemeList;
}

export async function GetSchemeListwithFilters(
  PageNumber: any,
  NofoScheme: any,
  SchemeName: any,
  AmcCode: any,
  ATFundLevelCategoryName: any,
  MRRatingOverall: any,
  dividendReinvestmentFlag: any,
  schemeType: any,
  sipmininvest: any,
  endDate: any,
  Return: any,
  sortMethod: any
) {
  const queryParams = [
    `amcCode=${AmcCode}`,
    `atFundLevelCategoryName=${ATFundLevelCategoryName}`,
    `mrRatingOverall=${MRRatingOverall}`,
    `dividendReinvestmentFlag=${dividendReinvestmentFlag}`,
    `endDate=${endDate}`,
    `pageNo=${PageNumber}`,
    `pageSize=${NofoScheme}`,
    `dpReturn=${Return}`,
    `schemeName=${SchemeName}`,
    `schemeType=${schemeType}`,
    `sipMinInvest=${sipmininvest}`,
    `sortingType=${sortMethod}`,
  ];

  const queryString = queryParams.join("&");

  const SchemeList = await get1(
    `i4e/v1/morningStar/getMFSchemes?${queryString}`
  );
  return SchemeList;
}
