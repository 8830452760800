import { http } from "./http";
// import { AppData } from "../store/app-data";
import { UserData } from "../store/user-data.js";

async function fireRequest<T>(path: string, args: RequestInit) {
  path = import.meta.env.VITE_APP_APISERVER + path;
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("Authorization", UserData.token);
  requestHeaders.set("Content-Type", `application/Json`);
  requestHeaders.set("Browser", UserData.device);
  requestHeaders.set("Loginsourcename", UserData.RedirectFrom);
  requestHeaders.set("ProductName", UserData.PorductName);
  requestHeaders.set("Cache-Control", "no-cache, no-store, must-revalidate");
  requestHeaders.set("Pragma", "no-cache");
  requestHeaders.set("Expires", "0");
  args.headers = requestHeaders;
  return await http<T>(new Request(path, args));
}

async function fireRequest3<T>(path: string, args: RequestInit) {
  path = import.meta.env.VITE_APP_APISERVER2 + path;
  const requestHeaders: HeadersInit = new Headers();
  // requestHeaders.set('Authorization', UserData.token);
  requestHeaders.set("Content-Type", `application/Json`);
  requestHeaders.set("Browser", UserData.device);
  requestHeaders.set("LoginSourceName", UserData.RedirectFrom);
  requestHeaders.set("ProductName", UserData.PorductName);
  requestHeaders.set("Cache-Control", "no-cache, no-store, must-revalidate");
  requestHeaders.set("Pragma", "no-cache");
  requestHeaders.set("Expires", "0");
  args.headers = requestHeaders;
  return await http<T>(new Request(path, args));
}

////////////////////////////////////////////////////////////////////////////////////
async function fireRequest1<T>(path: string, args: RequestInit) {
  path = import.meta.env.VITE_APP_APISERVER1 + path;
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("Authorization", UserData.token);
  requestHeaders.set("Content-Type", `application/Json`);
  requestHeaders.set("Browser", UserData.device);
  requestHeaders.set("LoginSourceName", UserData.RedirectFrom);
  requestHeaders.set("ProductName", UserData.PorductName);
  requestHeaders.set("Cache-Control", "no-cache, no-store, must-revalidate");
  requestHeaders.set("Pragma", "no-cache");
  requestHeaders.set("Expires", "0");
  args.headers = requestHeaders;
  return await http<T>(new Request(path, args));
}

async function certificatefireRequest1<T>(path: string, args: RequestInit) {
  path = import.meta.env.VITE_APP_NODEAPI + path;
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("Authorization", UserData.token);
  requestHeaders.set("Content-Type", `application/Json`);
  requestHeaders.set("Browser", UserData.device);
  requestHeaders.set("LoginSourceName", UserData.RedirectFrom);
  requestHeaders.set("ProductName", UserData.PorductName);
  requestHeaders.set("Cache-Control", "no-cache, no-store, must-revalidate");
  requestHeaders.set("Pragma", "no-cache");
  requestHeaders.set("Expires", "0");
  args.headers = requestHeaders;
  return await http<T>(new Request(path, args));
}

async function fireRequest2<T>(path: string, args: RequestInit) {
  path = import.meta.env.VITE_APP_APISERVER1 + path;
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("Authorization", UserData.token);
  requestHeaders.set("Browser", UserData.device);
  requestHeaders.set("LoginSourceName", UserData.RedirectFrom);
  requestHeaders.set("ProductName", UserData.PorductName);
  requestHeaders.set("Cache-Control", "no-cache, no-store, must-revalidate");
  requestHeaders.set("Pragma", "no-cache");
  requestHeaders.set("Expires", "0");
  args.headers = requestHeaders;
  return await http<T>(new Request(path, args));
}

export async function get1<T>(
  path: string,
  args: RequestInit = { method: "get" }
): Promise<T> {
  return await fireRequest1<T>(path, args);
}

export async function get<T>(
  path: string,
  args: RequestInit = { method: "get" }
): Promise<T> {
  return await fireRequest<T>(path, args);
}

export async function get2<T>(
  path: string,
  args: RequestInit = { method: "get" }
): Promise<T> {
  return await fireRequest3<T>(path, args);
}

export async function post<T>(
  path: string,
  body: any,
  args: RequestInit = { method: "post", body }
): Promise<T> {
  return await fireRequest1<T>(path, args);
}
export async function postCertificate<T>(
  path: string,
  body: any,
  args: RequestInit = { method: "post", body }
): Promise<T> {
  return await certificatefireRequest1<T>(path, args);
}
export async function post1<T>(
  path: string,
  body: any,
  args: RequestInit = { method: "post", body }
): Promise<T> {
  return await fireRequest2<T>(path, args);
}
export async function post2<T>(
  path: string,

  args: RequestInit = { method: "post" }
): Promise<T> {
  return await fireRequest2<T>(path, args);
}

export async function put<T>(
  path: string,
  body: any,
  args: RequestInit = { method: "put", body: JSON.stringify(body) }
): Promise<T> {
  return await fireRequest<T>(path, args);
}
export async function put1<T>(
  path: string,
  body: any,
  args: RequestInit = { method: "put", body }
): Promise<T> {
  return await fireRequest1<T>(path, args);
}
export async function put2<T>(
  path: string,

  args: RequestInit = { method: "put" }
): Promise<T> {
  return await fireRequest1<T>(path, args);
}

export async function patch<T>(
  path: string,
  body: any,
  args: RequestInit = { method: "patch", body: JSON.stringify(body) }
): Promise<T> {
  return await fireRequest1<T>(path, args);
}
//   return await fireRequest<T>(path, args);
// };

export async function patch1<T>(
  path: string,
  body?: any,
  args: RequestInit = { method: "delete", body: JSON.stringify(body) }
): Promise<T> {
  return await fireRequest1<T>(path, args);
}

export async function download<T>(
  path: string,
  body?: any,
  method: string = "get",
  args: RequestInit = { method: method, body }
) {
  // return await fireRequest1<T>(path, args);

  var xhr = new XMLHttpRequest();
  xhr.open(method, import.meta.env.VITE_APP_APISERVER + path, true);
  xhr.setRequestHeader("Authorization", UserData.token);
  xhr.setRequestHeader("Content-Type", `application/Json`);
  xhr.responseType = "blob";
  xhr.onload = function (e:any) {
    const blob = e.currentTarget.response;
    const contentDispo = e.currentTarget.getResponseHeader("Content-Disposition");

    var fileName = contentDispo.match(
      /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    )[1];

    var url = window.URL.createObjectURL(blob);
    console.log("a.href", url);
    var a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove();
  };
  xhr.send(body);
}
