const modalQuotesData = [
    {
      id: 1,
      quote: "Education is the key to unlock the golden door of freedom",
      author: "G. Washington Carver",
    },
  
    {
      id: 2,
      quote: "Education is the key to unlock the golden door of freedom",
      author: "G. Washington Carver",
    },
  ];
  
  export default modalQuotesData;
  