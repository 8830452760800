import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { errorMsgStyle, mandatoryStyle } from "./MUIStyles";
import { CircularProgress } from "@mui/material";
// This component is used to wrap * in the mandatory fields.
// Accepts children , sx - MUI styles props
export const CustomMandatoryText = ({ children, sx = {} }) => {
  return (
    <Typography
      variant="caption"
      sx={{ ...mandatoryStyle, fontWeight: 900, ...sx }}
    >
      {children}
    </Typography>
  );
};

export const CustomErrorText = ({ children, sx = {} }) => {
  return (
    <Typography variant="body2" sx={{ ...errorMsgStyle, ...sx }}>
      {children}
    </Typography>
  );
};

export const CustomLoadingScreen = () => {
  return <Skeleton variant="rectangular" width={210} height={60} />;
};

export const CustomLoadingSpinner = () => {
  return <CircularProgress size={30} />;
};

export const CustomLoadingCards = ({
  noOfCards = 1,
  width = 100,
  height = 50,
  animation = true,
  sx = {},
}) => {
  return Array.from({ length: noOfCards }, () => {
    return "hello";
  }).map((_, index) => {
    return (
      <Skeleton
        key={index}
        variant="rounded"
        width={width}
        height={height}
        animation={animation}
        sx={{ ...sx }}
      />
    );
  });
};
