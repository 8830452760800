/**
 * 
 * @param {string} number 
 * @returns {boolean}
 */
export const validatePhoneNumber = (number) => {
  if (number !== null && number !== undefined) {
    const phoneNumber = number;
    // const regex = /^\d{10}$/;
    const regexIND = /(6|7|8|9)\d{9}/;
    if (phoneNumber === "") { // Number is required condition
      return "Phone number is required";
    }
    if (regexIND.test(phoneNumber)) { // Number starts with 6 7 8 9 condition
      if (
        phoneNumber === 9999999999 ||
        phoneNumber === "9999999999" ||
        phoneNumber === 8888888888 ||
        phoneNumber === "8888888888" ||
        phoneNumber === 7777777777 ||
        phoneNumber === "7777777777" ||
        phoneNumber === 6666666666 ||
        phoneNumber === "6666666666"
      ) { // Fake number condition
        return `Please enter a valid Phone number`;
      }
      return ""; // number is valid
    }
    // if (regex.test(phoneNumber)) {
    //   return ""; // number is valid
    // }
    return "Please enter a valid Phone number"; // number is not valid
  }
};

/**
 * 
 * @param {string} text 
 * @returns {string}
 */
export const validateName = (text) => {
  // First step: check if the text contains only characters
  if (text === "") {
    return 'This field is required';
  }
  const check_chars_regex = /^[a-zA-Z]+$/;
  if (!check_chars_regex.test(text)) {
    return 'Please enter only alphabets';
  }
  // Second step: check if the text has only 2-50 characters
  const check_length_regex = /^.{2,50}$/;
  if (!check_length_regex.test(text)) {
    return 'Max length of characters supported is between 2 to 50';
  }
  // Third check: if none of the above return error then text is valid
  return '';
};

/**
 * 
 * @param {string} text 
 * @returns {string}
 */
export const validatePANNumber = (text) => {
  let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  if (text === "") {
    return "Please enter the PAN number";
  }
  else {
    if (regex.test(text)) {
      return "";
    }
    return "Please enter a valid PAN number";
  }
};

/**
 * 
 * @param {string} text 
 * @returns {string}
 */
export const validateText = (text) => {
  const regex = /^[a-zA-Z ]+$/;
  if (text === "") {
    return "Please fill this field";
  }
  if (regex.test(text)) {
    return '';
  }
  return 'Please enter only alphabets';
};

/**
 * 
 * @param {string} text 
 * @returns {string}
 */
export const validateEmail = (text) => {
  if (text === "") {
    return "Please fill this field";
  }
  let regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (regex.test(text)) {
    return '';
  }
  return 'Please enter valid email.';
};

/**
 * 
 * @param {string} text 
 * @returns {string}
 */
export const validatePara = (text) => {
  if (text === "") {
    return "Please fill this field";
  }
  let regex = /^([^\r\n]{2,500})+$/;
  if (regex.test(text)) {
    return '';
  }
  return 'Please enter valid text.';
};


// ! WRONG FILE, below functionality does not validate
// TODO: NEED TO MOVE BELOW CODE TO SEPARATE FILE -  utils.js
/**
 * 
 * @param {string} value 
 * @returns {string}
 */
export const currencyConversion = (value) => {
  // Remove commas, alphabets before parsing the value
  const sanitizedValue = value.toString().replace(/\D/g, '').replace(/,/g, '');
  // Parse the value to integer
  const parsedValue = parseInt(sanitizedValue === '' ? 0 : sanitizedValue, 10);
  // Format the parsed value with commas as thousands separator
  const formattedValue = parsedValue.toLocaleString('en-IN');
  return formattedValue
}
/**
 * 
 * @param {string} value 
 * @returns {number}
 */
export const removeCurrencyConversion = (value) => {
  try {
    const formattedValue = parseInt(value.replace(/\D/, "").replace(/,/g, ''), 10);
    return formattedValue
  } catch (error) {
    const formattedValue = parseInt(value, 10);
    return formattedValue
  }
}
export const isValidMobileNo = (mobileNo) => {
  return (
    isValidString(mobileNo) &&
    mobileNo.length === 10 &&
    /^\+?\d+$/.test(mobileNo)
  );
};
export const isValidString = (variable) => {
  return isValid(variable) && variable.length > 0;
};
export const isValid = (variable) => {
  return typeof variable !== "undefined" && variable !== null;
};

export const validatePassword = (password) => {
  // Regex to ensure password is at least 8 characters long and includes at least one special character.
  const passwordRegex = /^(?=.*[A-Za-z])[\w@#$]{8,64}$/;
  if (passwordRegex.test(password)) {
    return true;
  }
  return false;
};
  
