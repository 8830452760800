import { HttpResponse } from "./http-response";
import "react-toastify/dist/ReactToastify.css";

export async function http<T>(request: RequestInfo): Promise<T> {
  const response: HttpResponse<T> = await fetch(request);

  try {
    // may error if there is no body
    // console.log("response.status",response.headers.get("content-type"))

    if (response.headers.get("content-type") === "application/octet-stream") {
      // console.log("response.status--",response.headers.get("Content-Disposition"))
      const blob = await response.blob();
      var url =
        typeof window !== "undefined" && window.URL.createObjectURL(blob);
      console.log("a.href", url);
      var a: any = typeof window !== "undefined" && document.createElement("a");
      a.href = url;
      var date1 = new Date();
      a.download =
        "ActiveSIP_" +
        ("0" + date1.getDate()).slice(-2) +
        "_" +
        ("0" + (date1.getMonth() + 1)).slice(-2) +
        "_" +
        date1.getFullYear() +
        ".Xlsx";
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    }

    response.parsedBody = await response.json();
  } catch (ex) { }

  if (response.status == 401) {
    // console.log("status 401 in console")
    const event = new CustomEvent("Unauth", {
      detail: "User Session Time Out",
    });
    typeof window !== "undefined" && window.dispatchEvent(event);
  }

  if (response.ok) {
    // console.log("ServiceError");
    const ErrorResponse: any = response.parsedBody;
    // console.log("HTTP ERROR",ErrorResponse?.status?.message?.title)
    const event: any = new CustomEvent("SuccesMessage", {
      detail: ErrorResponse?.status?.message?.title,
    });
    typeof window !== "undefined" && window.dispatchEvent(event);
  }
  if (!response.ok) {
    // console.log("ServiceError");
    const ErrorResponse: any = response.parsedBody;
    // console.log("HTTP ERROR",ErrorResponse?.status?.message?.title)
    const event: any = new CustomEvent("errorMassage", {
      detail: ErrorResponse?.status?.message?.title,
    });
    typeof window !== "undefined" && window.dispatchEvent(event);
  }

  return response.parsedBody as T;
}
