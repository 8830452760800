import React, { useContext, useMemo, useState } from 'react'
import { CustomErrorText } from '../../components/UI/MUIComponents'
import { validateName } from '../../utils/validation'
import CircularProgress from '@mui/material/CircularProgress'
import { Col, Modal, Row } from 'react-bootstrap'
import { UserData } from '../../store/user-data'
import ivest4Edu from '../../components/Login/assets/i4E_icon.svg'
import { Link } from 'react-router-dom'
import '../../components/Login/assets/login.css'
import QuotesData from './QuotesData'
import { post as POST } from '../../utils/service-facade'
import { AppStateContext } from '../../state/AppProvider'

function Signup(props) {
  const initialInputData = {
    firstName: '',
    lastName: '',
    mobileNo: sessionStorage.getItem('clientID') || '',
    emailID: '',
    referralCode: ''
  }

  const initialErrors = {
    firstName: '',
    lastName: '',
    mobileNo: '',
    emailID: ''
  }

  const [inputData, setInputData] = useState(initialInputData)
  const [inputErrors, setInputErrors] = useState(initialErrors)
  const [globalError, setGlobalError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [JWT, setJWT] = useState(null)

  // dispatch to set JWT token in global state to be used in other components
  const { dispatch } = useContext(AppStateContext)

  const randomIndex = useMemo(() => {
    return Math.floor(Math.random() * QuotesData.length)
  }, [])
  const content = QuotesData[randomIndex]

  const handleClose = () => {
    props.setShowSignUpModal(false)
  }

  const handleChange = e => {
    // e.preventDefault();
    if (e.target.name === 'firstName' || e.target.name === 'lastName') {
      if (e.target.value && e.target.value.length > 250) {
        return
      }
      // clear the error message if the user is correcting the fields
      if (inputErrors[e.target.name] !== '') {
        setInputErrors(oldState => {
          const newState = { ...oldState, [e.target.name]: '' }
          return newState
        })
      }
    }
    setInputData({ ...inputData, [e.target.name]: e.target.value })
  }

  const validation = () => {
    let error = {}
    if (inputData.firstName === '') {
      error.firstName = 'First Name is required'
    } else {
      error.firstName = validateName(inputData.firstName)
    }
    if (inputData.lastName === '') {
      error.lastName = 'Last Name is required'
    } else {
      error.lastName = validateName(inputData.lastName)
    }
    if (inputData.emailID === '') {
      error.emailID = 'Email is required'
    } else {
      error.emailID = ''
    }
    const error_values = Object.values(error)
    if (error_values.every(item => item === '')) {
      setInputErrors(initialErrors)
      setGlobalError('')
      return true
    } else {
      setInputErrors(error)
      return false
    }
  }

  async function signUpUser(data) {
    setIsLoading(true)
    try {
      const response = await POST('i4e/v1/userprofile', JSON.stringify(data)).catch(err => console.error(err))

      if (response.status.resultType == 'FAILED' || response.status.resultType == 'FAILED') {
        setIsLoading(false)
      } else {
        // if no error in response
        setJWT(response)
        UserData.token = response.jwtToken
        sessionStorage.setItem('JWT', response.jwtToken)
        dispatch({ type: 'SET_JWT_TOKEN', payload: response.jwtToken })
        if (
          response.messageType === 'ERROR' ||
          response.title ===
            'id to load is required for loading; nested exception is java.lang.IllegalArgumentException: id to load is required for loading'
        ) {
          console.error(response.messageType)
          setIsLoading(false)
          return
        } else {
          // If redirectTo exists
          if (props.redirectTo) {
            window.location.href = props.redirectTo
          } else {
            // If redirectUser exists
            if (props.redirectUser === undefined) {
              //   window.location.href = window.location.pathname
              //   window.location.reload()
              // window.location.href = '/#/chat'
              window.location.href = '/#/'
            } else {
              window.location.href = import.meta.env.VITE_APP_WEBSITE_URL + props.redirectUser
            }
          }
          setIsLoading(false)
        }
      }
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (validation()) {
      setGlobalError('')
      let data = {
        agentName: '',
        agentBranch: '',
        clientCode: '',
        email: inputData.emailID,
        firstName: inputData.firstName,
        lastName: inputData.lastName,
        mobileNo: sessionStorage.getItem('clientID'),
        primary: true,
        referralCode: inputData.referralCode,
        relationshipType: 'SELF',
        userId: ''
      }
      signUpUser(data)
    } else {
      setGlobalError('Please fill the fields correctly and submit again')
    }
  }

  return (
    <Modal
      show={props.showSignUpModal}
      onHide={handleClose}
      className="modal-container emailVerify"
      dialogClassName="modal-90w"
      backdrop="static"
      keyboard={false}
      style={{ zIndex: '1400' }}>
      <Modal.Body>
        <Row className="d-flex">
          {/* {modalType === '' && ( */}
          <Col className="col-sm-6 modal-left-content">
            <p className="quote fs-30" style={{ fontStyle: 'italic' }}>
              {' '}
              &ldquo;{content.quote}&rdquo;.
            </p>
            <p className="author" style={{ fontStyle: 'italic' }}>
              {content.author}{' '}
            </p>
          </Col>
          {/* )} */}
          {/* {modalType === 'PAN' && <Col className={`col-sm-6 modal-left-content-${modalType} d-none d-lg-block`}></Col>}
        {modalType === 'PARTNER' && <Col className={`col-sm-6 modal-left-content d-none d-lg-block`}></Col>} */}
          <Col className="col-sm-12 col-md-12 col-lg-6 p-0 d-flex justify-content-center align-items-center">
            <div className="modal-right-content">
              <div className="modal-rightWrapper">
                <h4>
                  <img src={ivest4Edu} alt="ivest4Edu icon" className="modal-invest4Edu-icon" />
                </h4>
                <div className="modal-createAccount">
                  <h6>Create your Account</h6>
                  <p>Almost there... we are preparing something great for you!</p>
                  <form action="" className="form-flex" onSubmit={handleSubmit}>
                    <>
                      <label htmlFor="firstName">First Name*</label>
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder="Eg: Tommy"
                        value={inputData.firstName}
                        onChange={handleChange}
                        className="custom_modal_inputField"
                        required
                      />
                      {inputErrors.firstName && <CustomErrorText>{inputErrors.firstName}</CustomErrorText>}
                    </>
                    <>
                      <label htmlFor="lastName">Last Name*</label>
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Eg: Williamson"
                        value={inputData.lastName}
                        onChange={handleChange}
                        className="custom_modal_inputField"
                        required
                      />
                      {inputErrors.lastName && <CustomErrorText>{inputErrors.lastName}</CustomErrorText>}
                    </>
                    <>
                      <label htmlFor="mobileNo">Mobile No*</label>
                      <input
                        type="text"
                        name="mobileNo"
                        id="mobileNo"
                        placeholder="Eg: 9876543210"
                        value={inputData.mobileNo}
                        onChange={handleChange}
                        className="custom_modal_inputField"
                        required
                        disabled
                      />
                    </>
                    <>
                      <label htmlFor="emailID">Email ID*</label>
                      <input
                        type="email"
                        name="emailID"
                        id="emailID"
                        placeholder="Eg: your@email.com"
                        value={inputData.emailID}
                        onChange={handleChange}
                        className="custom_modal_inputField"
                        required
                      />
                      {inputErrors.emailID && <CustomErrorText>{inputErrors.emailID}</CustomErrorText>}
                    </>
                    <>
                      <label htmlFor="referralCode">Referral Code</label>
                      <input
                        type="text"
                        name="referralCode"
                        id="referralCode"
                        placeholder="Got any referral code? If not you can skip."
                        value={inputData.referralCode}
                        onChange={handleChange}
                        className="custom_modal_inputField"
                      />
                    </>
                    {globalError && <CustomErrorText>{globalError}</CustomErrorText>}
                    <button disabled={isLoading} type={'submit'} className="btn btn-primary requestOTP-btn">
                      {' '}
                      {isLoading && <CircularProgress size={20} />}
                      Continue
                    </button>
                  </form>
                </div>
                <p className="custom_modal_para">
                  By continuing, I agree to{' '}
                  <Link className="privacyPolicy" href="/PrivacyPolicy" target="_blank">
                    Privacy Policy
                  </Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default Signup
