import React, { useEffect, useState, memo, useRef, useMemo, useContext } from 'react'
import { post as POST } from '../../utils/service-facade'
import { UserData } from '../../store/user-data'
import { CustomErrorText, CustomMandatoryText } from '../../components/UI/MUIComponents'
import CustomTimer from '../../components/UI/CustomTimer'
import CircularProgress from '@mui/material/CircularProgress'
// import { useLocation } from "react-router-dom";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { validatePassword, validatePhoneNumber } from '../../utils/validation'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import NodeAPI from '@/Node/NodeAPI'
import { EmailValidation } from '../../utils/event'
import { toastMessage } from '../../utils/DateValidator'
import { Checkbox, FormControlLabel, Radio, Tooltip } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import QuotesData from './QuotesData'

// var CryptoJS = require('crypto-js')
import CryptoJS from 'crypto-js'
import { Container, Row, Col } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import ivest4Edu from '../../components/Login/assets/i4E_icon.svg'
import '../../components/Login/assets/login.css'
import NodeAPI from '../../api/NodeAPI'
import Signup from './Signup'
import { AppStateContext } from '../../state/AppProvider'
import SignupWithEmail from './SignupWithEmail'

function Login(props) {
  const { dispatch } = useContext(AppStateContext)
  const location = useLocation()
  const locationRef = useRef(location)
  const navigate = useNavigate()
  // to close the login modal upon navigating to different route
  useEffect(() => {
    if (locationRef.current !== location) {
      props.handleClose()
    }
    setSessionStorageForRedirectUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const randomIndex = useMemo(() => {
    return Math.floor(Math.random() * QuotesData.length)
  }, [])
  const content = QuotesData[randomIndex]

  const [otpRequested, setOTPRequested] = useState(false)
  const initialInputData = { mobileNo: '', OTP: '', Email: '', Password: '' }
  const [inputData, setInputData] = useState(initialInputData)
  const [isLoading, setIsLoading] = useState(false)
  const [reSendOTP, setReSendOTP] = useState(false)
  const [invalidOTP, setInvalidOTP] = useState(true)
  const [invalidNumber, setInvalidNumber] = useState(true)
  const [toggleTimer, setToggleTimer] = useState(false)

  // Functionality
  const [JWT, setJWT] = useState(null)
  const [verifyOTPLoader, setVerifyOTPLoader] = useState(false)
  const [count, setCount] = useState(0)
  const [emailCount, setEmailCount] = useState(0)
  const [redirectCount, setRedirectCount] = useState(0)

  // Error states
  const [showOTPError, setShowOTPError] = useState(false)
  const [OTPErrorMsg, setOTPErrorMsg] = useState('')
  const [emailLogin, setEmailLogin] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [globalError, setGloabalError] = useState('')
  const [showGlobalError, setShowGlobalError] = useState(false)
  const [partnerLogin, setPartnerLogin] = useState(false)
  const [showSignUpModal, setShowSignUpModal] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [showSignUpModalForEmail, setShowSignUpModalForEmail] = useState(false)

  const handleToggleTimer = value => {
    setToggleTimer(value)
  }

  const setSessionStorageForRedirectUrl = () => {
    sessionStorage.setItem('RedirectUrl', '')
  }

  const handleRequestOTP = () => {
    RequestOTPFunc()
  }

  const timerExpired = () => {
    handleToggleTimer(false)
    setReSendOTP(true)
    setOTPRequested(false)
  }

  // For handling onChange for Mobile number before requesting OTP
  const handleChangeBeforeOTP = e => {
    e.preventDefault()
    if (e.target.name === 'mobileNo') {
      if (e.target.value.length === 10) {
        setInvalidNumber(false)
      }
      if (e.target.value.length < 10) {
        setInvalidNumber(true)
      }
      if (e.target.value.length > 10) {
        return
      }
      setInputData({ ...inputData, [e.target.name]: e.target.value.replace(/\D/, '').replace(/,/g, '') ?? '' })
      return
    } else if (e.target.name === 'Email') {
      if (e.target.value.length <= 0) {
        toastMessage('Please enter valid Email')
      } else {
        setInvalidNumber(false)
      }
    }
    setInputData({ ...inputData, [e.target.name]: e.target.value })
  }

  // For handling onChange for Mobile number after requesting OTP
  const handleChangeAfterOTP = e => {
    e.preventDefault()
    setOTPRequested(false)
    setInvalidNumber(true)
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value.replace(/\D/, '').replace(/,/g, '') ?? ''
    })
  }

  // For handling onChange for OTP
  const handleChange = e => {
    e.preventDefault()
    if (e.target.name === 'OTP') {
      if (e.target.value.length) {
        if (e.target.value.length > 6) {
          return
        }
        e.target.value.length === 6 ? setInvalidOTP(false) : setInvalidOTP(true)
      }
    }
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value.replace(/\D/, '').replace(/,/g, '') ?? ''
    })
  }

  function handleSignUp() {
    setShowSignUpModalForEmail(true)
  }

  const handleSubmit = e => {
    if (otpRequested) {
      decryptOTPFunc()
    } else {
      handleRequestOTP()
    }
  }

  // call it when user wants to request OTP and also resend OTP
  async function RequestOTPFunc() {
    if (!emailLogin) {
      const mobileNo_IsValid = validatePhoneNumber(inputData.mobileNo)
      if (mobileNo_IsValid) {
        toast.error(mobileNo_IsValid)
        return
      }
      setIsLoading(true)
      try {
        let obj = { clientID: inputData.mobileNo }
        // const response = await POST(
        //   "i4e/v1/authentication/sendOtp",
        //   JSON.stringify(obj)
        // ).catch((err) => console.error(err));

        NodeAPI.tokenType = 'api'
        NodeAPI.sendOTP(
          inputData.mobileNo,
          async response => {
            if (response.responseCode === 200) {
              if (response.data.isSuccessfullyProcessed) {
                setOTPRequested(true)
                setIsLoading(false)
                setShowOTPError(false)
                var encryptedBase64Key = 'MW5WZXNUNEVkVUAyMDIyIQ=='
                var parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key)

                var decryptedData = CryptoJS.RC4.decrypt(response.data.data, parsedBase64Key, {
                  mode: CryptoJS.mode.ECB,
                  padding: CryptoJS.pad.Pkcs7
                })
                var decryptedText = JSON.parse(decryptedData.toString(CryptoJS.enc.Utf8))
                sessionStorage.setItem('decryptedText', decryptedText.response)
                sessionStorage.setItem('clientID', inputData.mobileNo)
                if (window.isSecureContext) {
                  // Copying OTP to clipboard
                  if (decryptedText.otp) {
                    try {
                      await navigator.clipboard.writeText(decryptedText.otp)
                    } catch (err) {
                      console.error('Failed to copy OTP to clipboard', err)
                    }
                  }
                }
              } else {
                setInputData(oldState => {
                  return { ...oldState, OTP: '' }
                })
                setOTPErrorMsg(response.data.error)
                setIsLoading(false)
                setShowOTPError(true)
                setOTPRequested(false)
              }
            } else {
              setInputData(oldState => {
                return { ...oldState, OTP: '' }
              })
              setOTPErrorMsg('')
              setIsLoading(false)
              setShowOTPError(true)
              setOTPRequested(false)
            }
          },
          () => {
            toast.error('Oops! Something went wrong. Please try again!', {})
          }
        )
      } catch (error) {
        console.error(error)
        setIsLoading(false)
      }
    } else {
      const Email_IsValid = EmailValidation(inputData.Email)
      if (!Email_IsValid) {
        setEmailError('Please enter valid Email')
        return
      }
      const Password_IsValid = validatePassword(inputData.Password)
      if (!Password_IsValid) {
        setPasswordError('Password must be at least 8 characters long and include at least one special character.')
        return
      }
      setIsLoading(true)
      try {
        setEmailError('')
        setPasswordError('')
        let obj = { emailId: inputData.Email, password: inputData.Password }
        NodeAPI.verifyUserEmail(
          obj,
          async response => {
            if (response.responseCode === 200) {
              if (response.data.error !== null) {
                setGloabalError(response.data.error)
                setShowGlobalError(true)
                setIsLoading(false)
                return
              } else {
                setShowGlobalError(false)
                sessionStorage.setItem('CLientEmail', inputData.Email)
                setCount(0)
                setRedirectCount(0)
                sessionStorage.setItem('RedirectCount', redirectCount)
                sessionStorage.setItem('count', count)
                if (response.data.isProfileAvailable) {
                  setEmailCount(0)
                  sessionStorage.setItem('EmailCount', emailCount)
                  sessionStorage.setItem('JWT', response.data.jwtToken)
                  // set JWT token in redux
                  dispatch({ type: 'SET_JWT_TOKEN', payload: response.data.jwtToken })
                  UserData.token = response.data.jwtToken
                  if (!props.skipRedirect) {
                    if (props.redirectTo === undefined) {
                      // window.location.href = '/#/chat'
                      window.location.href = '/#/'
                    } else {
                      if (props.redirectUser === undefined) {
                        window.location.href = currentURL
                      } else {
                        window.location.href = import.meta.env.VITE_APP_WEBSITE_URL + props.redirectUser
                      }
                    }
                  }
                  setIsLoading(false)
                } else {
                  // props.handleClose()
                  // props.setShowSignUpModal(true)
                  // props.setIsEmailLogin(emailLogin)
                  setShowSignUpModal(true)
                }
              }
            } else {
              setInputData(oldState => {
                return { ...oldState, OTP: '' }
              })
              setOTPErrorMsg('')
              setIsLoading(false)
              setShowOTPError(true)
              setOTPRequested(false)
              setGloabalError('Invalid Email or password.Please check!')
              setShowGlobalError(true)
              // toastMessage('Invalid Email or password. Please check!')
            }
          },
          () => {
            toast.error('Oops! Something went wrong. Please try again!', {})
          }
        )
      } catch (error) {
        console.error(error)
        setIsLoading(false)
      }
    }
  }
  // async function handleSignUp() {
  //   props.handleClose()
  //   props.setShowSignUpModal(true)
  //   props.setIsEmailLogin(emailLogin)
  // }

  async function decryptOTPFunc() {
    setIsLoading(true)
    try {
      const encryptedBase64Key = sessionStorage.getItem('decryptedText')
      const parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key)
      let plaintText = {
        clientID: inputData.mobileNo,
        password: inputData.OTP
      }
      let encryptedData = CryptoJS.RC4.encrypt(JSON.stringify(plaintText), parsedBase64Key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      })
      var obj2 = '{"clientID":"' + inputData.mobileNo + '","data":"' + encryptedData + '"}'
      const response = await POST('i4e/v1/authentication', obj2).catch(err => console.error(err))
      if (response.data) {
        let encryptedCipherText = response.data
        let decryptedData = CryptoJS.RC4.decrypt(encryptedCipherText, parsedBase64Key, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        })
        let decryptedTextJSON = decryptedData.toString(CryptoJS.enc.Utf8)
        let decryptedText = JSON.parse(decryptedTextJSON)
        setJWT(decryptedText)
        let currentURL = window.location.href
        setVerifyOTPLoader(false)
        UserData.token = decryptedText.jwt
        setCount(0)
        setRedirectCount(0)
        sessionStorage.setItem('RedirectCount', redirectCount)
        sessionStorage.setItem('JWT', decryptedText.jwt)
        // set JWT token in redux
        dispatch({ type: 'SET_JWT_TOKEN', payload: decryptedText.jwt })
        sessionStorage.setItem('count', count)
        setShowOTPError(false)
        setOTPErrorMsg('')
        // to check if the user is already registered
        if (decryptedText.isProfileAvailable) {
          setEmailCount(0)
          sessionStorage.setItem('EmailCount', emailCount)
          // partner login
          if (partnerLogin) {
            const encryptToken = CryptoJS.RC4.encrypt(decryptedText.jwt, import.meta.env.VITE_APP_B2B_KEY)
            sessionStorage.removeItem('JWT') // ! B2B user need not be logged into I4E platform
            window.location.href = `${
              import.meta.env.VITE_APP_B2B_URL
            }?token=${encodeURIComponent(encryptToken.toString())}`
          }
          // non-partner login
          else {
            if (!props.skipRedirect) {
              if (props.redirectTo === undefined) {
                // window.location.href = '/#/chat'
                window.location.href = '/#/'
              } else {
                if (props.redirectUser === undefined) {
                  window.location.href = currentURL
                } else {
                  window.location.href = import.meta.env.VITE_APP_WEBSITE_URL + props.redirectUser
                }
              }
            }
          }
        }
        // if user is not registered
        else {
          if (!partnerLogin) {
            // const toastContent = (
            //   <div>
            //     Uh oh! You are not registered with us. Please go to{' '}
            //     <a href="https://invest4edu.com" target="_blank" rel="noopener noreferrer">
            //       https://invest4edu.com
            //     </a>{' '}
            //     to register
            //   </div>
            // )
            // toast.error(toastContent)
            // props.handleClose()
            // props.setShowSignUpModal(true)
            setShowSignUpModal(true)
          } else {
            toast.error('Partner not registered')
          }
        }
        if (UserData.token) {
          try {
            props.onLoginSuccess()
          } catch (error) {
            console.error('unknown error')
          }
        }
      } else if (response.messageType === 'ERROR' || response.code === 'OTP is invalid, please enter correct OTP') {
        setJWT(response)
        setVerifyOTPLoader(false)
        let newInputData = { ...inputData, OTP: '' }
        setInputData({ ...newInputData })
        setShowOTPError(true)
        setOTPErrorMsg(response.code)
        setInvalidOTP(true)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  return (
    <>
      <div className="d-flex modal-container">
        <Row
          className="d-flex"
          style={{ border: '1px solid lightgrey', borderRadius: '20px', margin: 0, height: '100vh', width: '100%' }}>
          {/* {modalType === '' && ( */}
          <Col className="col-sm-6 col-lg-9 modal-left-content">
            <p className="quote fs-30" style={{ fontStyle: 'italic' }}>
              {' '}
              &ldquo;{content.quote}&rdquo;.
            </p>
            <p className="author" style={{ fontStyle: 'italic' }}>
              {content.author}{' '}
            </p>
          </Col>
          {/* )} */}
          {/* {modalType === 'PAN' && <Col className={`col-sm-6 modal-left-content-${modalType} d-none d-lg-block`}></Col>}
        {modalType === 'PARTNER' && <Col className={`col-sm-6 modal-left-content d-none d-lg-block`}></Col>} */}
          <Col className="col-sm-12 col-md-12 col-lg-3 p-0 d-flex justify-content-center align-items-center">
            <div className="modal-right-content">
              <div className="modal-rightWrapper">
                <h4>
                  <img src={ivest4Edu} alt="ivest4Edu icon" className="modal-invest4Edu-icon" />
                </h4>
                {/* {children} */}
                <div className="modal-loginOTP">
                  <h6 className="custom_modal_h6">Login to your Account</h6>
                  <p className="custom_modal_para">See what is going on with your Edu Journey</p>
                  {/* {!props.isPartnerLogin && ( */}
                  <div>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={emailLogin === false}
                          onChange={() => {
                            setEmailLogin(false)
                            sessionStorage.setItem('isEmailLogin', false)
                          }}
                          size="small"
                          value="Mobile Number"
                        />
                      }
                      label="Mobile Number"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={emailLogin === true}
                          onChange={() => {
                            setEmailLogin(true)
                            sessionStorage.setItem('isEmailLogin', true)
                          }}
                          size="small"
                          value="Email"
                        />
                      }
                      label="Email"
                    />
                  </div>
                  {/* )} */}
                  {!emailLogin ? (
                    <>
                      <label htmlFor="mobileNo" className="mt-0 custom_modal_label">
                        Mobile No
                      </label>
                      <input
                        type="text"
                        name="mobileNo"
                        id="mobileNo"
                        className="custom_modal_inputField"
                        placeholder="Eg: 9876543210"
                        onChange={otpRequested ? handleChangeAfterOTP : handleChangeBeforeOTP}
                        value={inputData.mobileNo}
                      />
                      {otpRequested && (
                        <>
                          <label htmlFor="OTP" className="custom_modal_label">
                            OTP
                          </label>
                          <input
                            type="text"
                            name="OTP"
                            id="OTP"
                            className="custom_modal_inputField"
                            placeholder="Enter OTP here"
                            onChange={handleChange}
                            value={inputData.OTP}
                          />
                          {toggleTimer && (
                            <p style={{ fontSize: '12px', margin: '0.3rem' }}>
                              <CustomTimer timerExpired={timerExpired} />
                            </p>
                          )}
                        </>
                      )}
                      {showOTPError && <CustomErrorText>{OTPErrorMsg}</CustomErrorText>}
                      <button
                        className="btn btn-primary requestOTP-btn"
                        onClick={handleSubmit}
                        disabled={isLoading ? true : otpRequested ? invalidOTP : invalidNumber} // 1st check - If button is loading,
                        // 2nd check - If the OTP is requested or not
                        // if 2nd check is true - OTP is valid or not
                        // if 2nd check is false - Check if number is valid or not
                      >
                        {isLoading && <CircularProgress size={20} />}
                        {otpRequested ? `Continue` : reSendOTP ? 'Resend OTP' : 'Request OTP'}
                      </button>
                    </>
                  ) : (
                    <>
                      <label htmlFor="Email" className="mt-0 custom_modal_label">
                        Email <CustomMandatoryText>*</CustomMandatoryText>
                      </label>
                      <input
                        type="text"
                        name="Email"
                        id="Email"
                        className="custom_modal_inputField"
                        placeholder="Eg: test@gmal.com"
                        onChange={otpRequested ? handleChangeAfterOTP : handleChangeBeforeOTP}
                        value={inputData.Email}
                      />
                      {emailError && (
                        <CustomErrorText sx={{ padding: '0px', fontSize: '0.8rem' }}>{emailError}</CustomErrorText>
                      )}
                      <div style={{ position: 'relative' }}>
                        <label htmlFor="password" className="mt-0 custom_modal_label">
                          Password <CustomMandatoryText>*</CustomMandatoryText>
                        </label>
                        <input
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          id="password"
                          className="custom_modal_inputField"
                          placeholder="Eg: ******"
                          onChange={e => setInputData({ ...inputData, Password: e.target.value })}
                          value={inputData.Password}
                          required
                          style={{ paddingRight: '2rem' }}
                        />
                        <Tooltip title={showPassword ? 'Hide Password' : 'Show Password'}>
                          <Checkbox
                            checked={showPassword}
                            icon={<VisibilityIcon />}
                            checkedIcon={<VisibilityOffIcon />}
                            onChange={() => setShowPassword(!showPassword)}
                            size="small"
                            color="default"
                            sx={{ position: 'absolute', right: '2%', bottom: '5%' }}
                          />
                        </Tooltip>
                      </div>
                      {passwordError && (
                        <CustomErrorText sx={{ padding: '0px', fontSize: '0.8rem' }}>{passwordError}</CustomErrorText>
                      )}
                      {otpRequested && (
                        <>
                          <label htmlFor="OTP" className="custom_modal_label">
                            OTP
                          </label>
                          <input
                            type="text"
                            name="OTP"
                            id="OTP"
                            className="custom_modal_inputField"
                            placeholder="Enter OTP here"
                            onChange={handleChange}
                            value={inputData.OTP}
                          />
                          {toggleTimer && (
                            <p style={{ fontSize: '12px', margin: '0.3rem' }}>
                              <CustomTimer timerExpired={timerExpired} />
                            </p>
                          )}
                        </>
                      )}
                      <button
                        className="btn btn-primary requestOTP-btn"
                        onClick={handleSubmit}
                        disabled={isLoading ? true : otpRequested ? invalidOTP : invalidNumber} // 1st check - If button is loading,
                      >
                        {isLoading && <CircularProgress size={20} />}
                        Login
                      </button>
                      {showGlobalError && <CustomErrorText sx={{ fontSize: '13px' }}>{globalError}</CustomErrorText>}
                    </>
                  )}
                </div>
                {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
                {/* <div>
          <p className="custom_modal_para mb-0" style={{ cursor: 'pointer' }} onClick={() => {
            setEmailLogin(!emailLogin); 
            sessionStorage.setItem("isEmailLogin", !emailLogin);
          }}>
            Sign in using {emailLogin ? "Mobile" : "Email"}
          </p>
        </div> */}
                {emailLogin && (
                  <div>
                    <p className="custom_modal_para mb-0">
                      New here ?{' '}
                      <span
                        style={{ color: '#007bff', cursor: 'pointer' }}
                        onClick={() => {
                          handleSignUp()
                        }}>
                        Create an Account
                      </span>
                    </p>
                  </div>
                )}
                {/* </div> */}
                {/* {noPrivacyPolicy && ( */}
                <p className="custom_modal_para">
                  By continuing, I agree to{' '}
                  <Link className="privacyPolicy" href="/PrivacyPolicy" target="_blank">
                    Privacy Policy
                  </Link>
                </p>
                {/* )} */}
                {/* // button to navigate to chat page without login */}
                <button
                  className="btn btn-primary requestOTP-btn"
                  onClick={() => {
                    navigate('/')
                  }}>
                  Try chat now
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* // sign up modal will be shown when user is not registered */}
      {showSignUpModal && <Signup showSignUpModal={showSignUpModal} setShowSignUpModal={setShowSignUpModal} />}

      {/* // show sign up modal for email */}
      {showSignUpModalForEmail && (
        <SignupWithEmail showSignUpModal={showSignUpModalForEmail} setShowSignUpModal={setShowSignUpModalForEmail} />
      )}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  )
}

export default memo(Login)
