/* eslint-disable default-case */
if (typeof window !== "undefined") {
  window.onhashchange = function (event) {
    event.preventDefault();
    window.scrollTo(
      0,
      document.querySelector(window.location.hash).getBoundingClientRect().top +
        window.scrollY -
        100
    );
    // console.log("arguments",arguments);
  };
}

export const NameValidation = (text) => {
  let regex = /^[a-zA-Z]{1,50}$/;
  let regex1 = /^([a-zA-Z]{1,})+\s+([a-zA-Z]{1,})+$/i;
  let regex2 = /^([A-Za-z]{1,})+\s+([A-Za-z]{1,})+\s+([A-Za-z]{1,})+$/i;
  if (regex.test(text) || regex1.test(text) || regex2.test(text)) {
    return true;
  }
  return false;
};

export const SignFirstNameValidation = (text) => {
  let regex = /^[a-zA-Z]{2,50}$/;
  if (regex.test(text)) {
    return true;
  }
  return false;
};

export const SignLastNameValidation = (text) => {
  let regex = /^[a-zA-Z]{2,50}$/;
  if (regex.test(text)) {
    return true;
  }
  return false;
};

export const LastNameValidation = (text) => {
  let regex = /^[a-zA-Z]{1,50}$/;
  if (regex.test(text)) {
    return true;
  }
  return false;
};

export const PanNameValidation = (text) => {
  let regex = /^([A-Za-z]{1,})+\s+([A-Za-z]{1,})+\s+([A-Za-z]{1,})+$/i;
  let regex1 = /^([a-zA-Z]{1,})+\s+([a-zA-Z]{1,})+$/i;
  if (regex.test(text) || regex1.test(text)) {
    return true;
  }
  return false;
};

export const pancardValidation = (text) => {
  let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  if (regex.test(text)) {
    return true;
  }
  return false;
};

export const EmailValidation = (text) => {
  let regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (regex.test(text)) {
    return true;
  }
  return false;
};

export const Dobvalidation = (text) => {
  let regex =
    /^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
  if (regex.test(text)) {
    return true;
  }
  return false;
};

export const BankNameValidation = (text) => {
  let regex = /^[a-zA-Z]{5,50}$/;

  if (regex.test(text)) {
    return true;
  }
  return false;
};

export const InputValidation = (text) => {
  let regex = /^[a-zA-Z]{3,40}$/;
  if (regex.test(text)) {
    return true;
  }
  return false;
};

export const AddressValidation = (text) => {
  let regex = /^[a-zA-Z0-9\s,,:./()-]{3,}$/;
  if (regex.test(text)) {
    return true;
  }
  return false;
};

export const Pincode = (text) => {
  let regex = /^[0-9]{6}$/;
  if (regex.test(text)) {
    return true;
  }
  return false;
};

export const MoblieNumberValidation = (text) => {
  let regex = /(6|7|8|9)\d{9}/;
  if (regex.test(text)) {
    if (
      text === 9999999999 ||
      text === "9999999999" ||
      text === 8888888888 ||
      text === "8888888888" ||
      text === 7777777777 ||
      text === "7777777777" ||
      text === 6666666666 ||
      text === "6666666666"
    ) {
      return false;
    } else {
      return true;
    }
  }
  return false;
};

export const removeSpaces = (string) => {
  return string.split(" ").join("");
};

export const getNumberInWords = (num) => {
  if (num < 10) {
    if (num == 0) {
      return "Zero";
    }
    return belowTen(num);
  } else if (num < 20) {
    return belowTwenty(num);
  } else if (num < 1000) {
    return belowThousand(num);
  } else if (num < 100) {
    return belowHundred(num);
  } else if (num < 100000) {
    return belowLakh(num);
  } else if (num < 10000000) {
    return belowCrore(num);
  } else if (num < 1000000000) {
    return belowBilion(num);
  }
  return "";
};

const belowTen = (x) => {
  switch (x) {
    case 1:
      return "One ";
    case 2:
      return "Two ";
    case 3:
      return "Three ";
    case 4:
      return "Four ";
    case 5:
      return "Five ";
    case 6:
      return "Six ";
    case 7:
      return "Seven ";
    case 8:
      return "Eight ";
    case 9:
      return "Nine ";
  }
  return "";
};

const belowTwenty = (x) => {
  switch (x) {
    case 10:
      return "Ten ";
    case 11:
      return "Eleven ";
    case 12:
      return "Twelve ";
    case 13:
      return "Thirteen ";
    case 14:
      return "Fourteen ";
    case 15:
      return "Fifteen ";
    case 16:
      return "Sixteen ";
    case 17:
      return "Seventeen ";
    case 18:
      return "Eighteen ";
    case 19:
      return "Nineteen ";
  }
  return "";
};

const belowHundred = (x) => {
  if (x < 10) return belowTen(x);
  else if (x < 20) return belowTwenty(x);
  switch (leftChars(x, 1)) {
    case 2:
      return "Twenty " + belowTen(rightChars(x, 1));
    case 3:
      return "Thirty " + belowTen(rightChars(x, 1));
    case 4:
      return "Forty " + belowTen(rightChars(x, 1));
    case 5:
      return "Fifty " + belowTen(rightChars(x, 1));
    case 6:
      return "Sixty " + belowTen(rightChars(x, 1));
    case 7:
      return "Seventy " + belowTen(rightChars(x, 1));
    case 8:
      return "Eighty " + belowTen(rightChars(x, 1));
    case 9:
      return "Ninety " + belowTen(rightChars(x, 1));
  }
  return "";
};

const belowThousand = (x) => {
  if (x < 10) {
    return belowTen(x);
  } else if (x < 20) {
    return belowTwenty(x);
  } else if (x < 100) {
    return belowHundred(x);
  }
  return (
    belowTen(leftChars(x, 1)) + "Hundred " + belowHundred(rightChars(x, 2))
  );
};

const belowLakh = (x) => {
  if (x < 10) {
    return belowTen(x);
  } else if (x < 20) {
    return belowTwenty(x);
  } else if (x < 100) {
    return belowHundred(x);
  } else if (x < 1000) {
    return belowThousand(x);
  }
  if (x.toString().length === 4) {
    return (
      belowTen(leftChars(x, 1)) + "Thousand " + belowThousand(rightChars(x, 3))
    );
  } else {
    return (
      belowHundred(leftChars(x, 2)) +
      "Thousand " +
      belowThousand(rightChars(x, 3))
    );
  }
};

const belowCrore = (x) => {
  if (x < 10) {
    return belowTen(x);
  } else if (x < 20) {
    return belowTwenty(x);
  } else if (x < 100) {
    return belowHundred(x);
  } else if (x < 1000) {
    return belowThousand(x);
  } else if (x < 100000) {
    return belowLakh(x);
  }
  if (x.toString().length == 6) {
    return belowTen(leftChars(x, 1)) + "Lakh " + belowLakh(rightChars(x, 5));
  } else {
    return (
      belowHundred(leftChars(x, 2)) + "Lakh " + belowLakh(rightChars(x, 5))
    );
  }
};

const belowBilion = (x) => {
  if (x < 10) {
    return belowTen(x);
  } else if (x < 20) {
    return belowTwenty(x);
  } else if (x < 100) {
    return belowHundred(x);
  } else if (x < 1000) {
    return belowThousand(x);
  } else if (x < 100000) {
    return belowLakh(x);
  } else if (x < 10000000) {
    return belowCrore(x);
  }
  if (x.toString().length == 8) {
    return belowTen(leftChars(x, 1)) + "Crore " + belowCrore(rightChars(x, 7));
  } else {
    return (
      belowHundred(leftChars(x, 2)) + "Crore " + belowCrore(rightChars(x, 7))
    );
  }
};

const leftChars = (num, n) => {
  return parseInt(leftChars1(num.toString(), n));
};

const rightChars = (num, n) => {
  return parseInt(rightChars1(num.toString(), n));
};

const leftChars1 = (str, n) => {
  if (str.length <= n) return str;
  else return str.substring(0, n);
};

const rightChars1 = (str, n) => {
  if (str.length <= n) return str;
  else return str.substring(str.length - n, str.length);
};
import moment from "moment";

export function removeSessionStorage() {
  typeof window !== "undefined" && sessionStorage.clear();
}

export const validateText = (value) => {
  const regex = /^[a-zA-Z ]+$/;
  if (regex.test(value)) {
    return true;
  }
  return false;
};
export const isStartButtonVisible = (startDateTime) => {
  const fiveMinutesInMilliseconds = 5 * 60 * 1000;
  const currentTime = new Date();
  const formattedCurrentTime = new Date(
    moment(currentTime).format("YYYY-MM-DD HH:mm:ss")
  );
  const formattedStartDateTime = new Date(
    moment(startDateTime.split("Z")[0]).format("YYYY-MM-DD HH:mm:ss")
  );
  const timeDifference = formattedStartDateTime - formattedCurrentTime;

  return timeDifference <= fiveMinutesInMilliseconds;
};
export const isEndMeetingCheck = (currentTime, endDateTime) => {
  const formattedCurrentTime = new Date(
    moment(currentTime).format("YYYY-MM-DD HH:mm:ss")
  );
  const formattedendDateTime = new Date(
    moment(endDateTime.split("Z")[0]).format("YYYY-MM-DD HH:mm:ss")
  );
  return formattedCurrentTime >= formattedendDateTime;
};
