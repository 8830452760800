import { useContext, useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Dialog, Stack, TextField } from '@fluentui/react'
import { CopyRegular } from '@fluentui/react-icons'

import { CosmosDBStatus } from '../../api'
import Contoso from '../../assets/Contoso.svg'
import { HistoryButton, ShareButton } from '../../components/common/Button'
import { AppStateContext } from '../../state/AppProvider'

import styles from './Layout.module.css'

const Layout = () => {
  const [isSharePanelOpen, setIsSharePanelOpen] = useState<boolean>(false)
  const [copyClicked, setCopyClicked] = useState<boolean>(false)
  const [copyText, setCopyText] = useState<string>('Copy URL')
  const [shareLabel, setShareLabel] = useState<string | undefined>('Share')
  const [hideHistoryLabel, setHideHistoryLabel] = useState<string>('Hide chat history')
  const [showHistoryLabel, setShowHistoryLabel] = useState<string>('Show chat history')
  const appStateContext = useContext(AppStateContext)
  const ui = appStateContext?.state.frontendSettings?.ui
  // to check if user is logged in or not to show logout button
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation()

  const { state }: any = appStateContext
  const { JWT } = state

  const handleShareClick = () => {
    setIsSharePanelOpen(true)
  }

  const handleSharePanelDismiss = () => {
    setIsSharePanelOpen(false)
    setCopyClicked(false)
    setCopyText('Copy URL')
  }

  const handleCopyClick = () => {
    navigator.clipboard.writeText(window.location.href)
    setCopyClicked(true)
  }

  const handleHistoryClick = () => {
    appStateContext?.dispatch({ type: 'TOGGLE_CHAT_HISTORY' })
  }

  useEffect(() => {
    if (copyClicked) {
      setCopyText('Copied URL')
    }
  }, [copyClicked])

  useEffect(() => {}, [appStateContext?.state.isCosmosDBAvailable.status])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 480) {
        setShareLabel(undefined)
        setHideHistoryLabel('Hide history')
        setShowHistoryLabel('Show history')
      } else {
        setShareLabel('Share')
        setHideHistoryLabel('Hide chat history')
        setShowHistoryLabel('Show chat history')
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // useffect for triggering the JWT if exists
  useEffect(() => {
    if (sessionStorage.getItem('JWT')) {
      setIsLoggedIn(true)
      appStateContext?.dispatch({ type: 'SET_JWT_TOKEN', payload: sessionStorage.getItem('JWT') ?? '' })
      // navigate('chat')
      navigate('/')
    }
    // else {
    //   navigate('/')
    // }
  }, [location.pathname])

  const handleLogout = () => {
    // sessionStorage.removeItem('JWT')
    sessionStorage.clear()
    window.location.href = '/'
  }

  return (
    <div className={styles.layout}>
      <header
        className={styles.header}
        role={'banner'}
        style={{
          boxShadow: '0px 0px 5px -1px rgba(0, 0, 0, 0.5)',
          backgroundColor: 'white',
          display: location.pathname === '/login' || location.pathname === '/#/login' ? 'none' : 'block',
          // visibility: 'hidden'
        }}>
        <Stack horizontal verticalAlign="center" horizontalAlign="space-between" style={{ padding: '1rem' }}>
          <Stack horizontal verticalAlign="center">
            <img src={ui?.logo ? ui.logo : Contoso} className={styles.headerIcon} aria-hidden="true" alt="" />
            <Link to="/" className={styles.headerTitleContainer}>
              <h1 className={styles.headerTitle}>{ui?.title}</h1>
            </Link>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 4 }} className={styles.shareButtonContainer}>
            {appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured && (
              <HistoryButton
                onClick={handleHistoryClick}
                text={appStateContext?.state?.isChatHistoryOpen ? hideHistoryLabel : showHistoryLabel}
              />
            )}
            {ui?.show_share_button && <ShareButton onClick={handleShareClick} text={shareLabel} />}
            {JWT ? (
              <div
                onClick={handleLogout}
                className={styles.headerTitleContainer}
                style={{ borderRadius: '2px', display: 'flex', alignItems: 'baseline' }}>
                <button
                  className={styles.headerTitle}
                  style={{
                    fontSize: '14px',
                    border: '1px solid #d1d1d1',
                    backgroundColor: '#1a2d5a',
                    color: 'white',
                    borderRadius: '20px',
                    width: '100px',
                    justifyContent: 'center'
                  }}>
                  Logout
                </button>
              </div>
            ) : (
              <div
                onClick={() => navigate('login')}
                className={styles.headerTitleContainer}
                style={{ borderRadius: '2px', display: 'flex', alignItems: 'baseline' }}>
                <button
                  className={styles.headerTitle}
                  style={{
                    fontSize: '14px',
                    border: '1px solid #d1d1d1',
                    backgroundColor: '#1a2d5a',
                    color: 'white',
                    borderRadius: '20px',
                    width: '100px',
                    justifyContent: 'center'
                  }}>
                  Login
                </button>
              </div>
              // <></>
            )}
          </Stack>
        </Stack>
      </header>
      <Outlet />
      <Dialog
        onDismiss={handleSharePanelDismiss}
        hidden={!isSharePanelOpen}
        styles={{
          main: [
            {
              selectors: {
                ['@media (min-width: 480px)']: {
                  maxWidth: '600px',
                  background: '#FFFFFF',
                  boxShadow: '0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)',
                  borderRadius: '8px',
                  maxHeight: '200px',
                  minHeight: '100px'
                }
              }
            }
          ]
        }}
        dialogContentProps={{
          title: 'Share the web app',
          showCloseButton: true
        }}>
        <Stack horizontal verticalAlign="center" style={{ gap: '8px' }}>
          <TextField className={styles.urlTextBox} defaultValue={window.location.href} readOnly />
          <div
            className={styles.copyButtonContainer}
            role="button"
            tabIndex={0}
            aria-label="Copy"
            onClick={handleCopyClick}
            onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? handleCopyClick() : null)}>
            <CopyRegular className={styles.copyButton} />
            <span className={styles.copyButtonText}>{copyText}</span>
          </div>
        </Stack>
      </Dialog>
    </div>
  )
}

export default Layout
